.wizard-navi {
    margin-bottom: 15px;
    text-align: center;
}

.wizard-navi .dot {
    color:#d2ddec;

    /* cursor: pointer; */
    font-size: 52pt;
    line-height: 1;
    margin: 10px 30px;
    text-shadow: none;
    transition: opacity 1s ease, text-shadow 1s ease;
    will-change: opacity, text-shadow;
}

.wizard-navi .dot.active {
    color: #001A49;
    text-shadow: 0 0 8px;
}

.header-actions {
    position: absolute;
    top: 15px;
    right: 0;
    display: flex;
    flex-direction: row;
}

.header-actions > * {
    margin-right: 15px;
}

.btn-product-save.disabled {
    visibility: hidden;
}

.btn-product-cancel.disabled {
    visibility: hidden;
}